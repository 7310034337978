<template>
  <div>
    <Site-Table 
      :options="{
        site_name_link: 'edit_page',
        settings: true,
        forms: true
      }"
    ></Site-Table>
  </div>
</template>

<script>
import SiteTable from '../components/SiteTable'

export default {
  name: 'Sites',
  components: {
    SiteTable
  },
}
</script>

<style lang="scss">

</style>